import { useHistory } from "react-router";

import Button from "../design-system/Button";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Partner from "../components/Partner";
import CenterWrapper from "../design-system/CenterWrapper";

const Home = () => {
  const history = useHistory();
  const capabilities = [
    {
      iconName: "/assets/svg/home/fulfillment.gif",
      title: "Fixed Asset Management",
      desc: "Your ultimate solution for organizing, tracking, and maximizing the value of your company's assets. Picture it as your digital command center, where you have full control and visibility over all your assets, whether they're physical equipment, digital resources, or intellectual property.",
    },
    {
      iconName: "/assets/svg/home/collaboration.gif",
      title: "Order Collaboration and Invoicing",
      desc: "A powerful tool designed to streamline and enhance the process of managing orders within a business ecosystem. Think of it as a digital hub where all stakeholders involved in the order process from sales teams to suppliers to logistics partners can collaborate seamlessly in real-time",
    },
    {
      iconName: "/assets/svg/home/manufacturing-execution.gif",
      title: "Manufacturing and Execution",
      desc: "Manufacturing and Execution are the secret sauce behind getting your product from concept to customer, ensuring a smooth journey and a satisfied audience!",
    },
  ];

  return (
    <>
      <Header />

      <div>
        {/* intro */}
        <CenterWrapper backgroundColor="grey">
          <div className="flex justify-between bg-n-100 items-center flex-col-reverse lg:flex-row">
            <div className="w-[calc(100vw-40px)] lg:w-[36.25rem] pl-[20px] pr-[20px] lg:pl-[96px] lg:pr-0 flex flex-col gap-[24px]">
              <p className="typography-h400-bold text-n-700">
                We Don't just Solve Problems, we Build a Bridge to your Future
              </p>
              <p className="typography-h300 text-n-300">
                We Provide consulting services to power digital transformation
                at the same level of quality, but at a lower price point by
                leveraging offshore resources.
              </p>
              <Button.Secondary
                id="pricing"
                onClick={() => history.replace("/plans")}
                customClass="mb-[40px]"
              >
                See all Plans
              </Button.Secondary>
            </div>
            <img
              src="/assets/svg/home/illustration_top.svg"
              className="w-[60%] lg:w-[38.75rem] lg:mt-0"
              alt="provenant logo"
            />
          </div>
        </CenterWrapper>

        {/* capabilities */}
        <CenterWrapper>
          <div className="bg-n-000 px-[20px] lg:px-[96px] py-[24px] lg:py-[56px]">
            <p className="typography-h300-bold text-n-700 mb-[8px]">
              Our Primary Capabilities
            </p>
            <p className="typography-h500-bold text-n-700 ">
              Help your Business Succeed with
            </p>
            <div className="flex justify-between flex-col lg:flex-row mt-[56px]">
              {capabilities?.map((cap) => (
                <div className="w-[calc(100vw-40px)] lg:w-[22.75rem] mb-[28px] lg:mb-0 flex flex-col items-center">
                  <img
                    src={cap?.iconName}
                    className="w-[80px] h-[80px] lg:w-[128px] lg:h-[128px]"
                    alt="capability logo"
                  />
                  <p className="mt-[12px] h-auto lg:h-[4.5rem] typography-h400-bold text-n-700 text-center w-[10.125rem] lg:w-[19.75rem]">
                    {cap?.title}
                  </p>
                  <p className="mt-[24px] lg:mt-[40px] h-full lg:h-[15.75rem] typography-h340 text-n-300 text-center">
                    {cap?.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </CenterWrapper>

        {/* eight capabilities */}
        <CenterWrapper>
          <div className="pt-[24px] pb-[48px] px-[20px] lg:pt-[64px] lg:pb-[196px] lg:px-[96px]">
            <p className="typography-h500-bold text-n-700 text-center lg:text-left">
              Eight Capabilities
            </p>
            <div className="mt-[48px] lg:mt-[24px] flex justify-center">
              <img
                src="/assets/svg/home/eight-capabilities.svg"
                alt="eight capabilities"
                className="w-[360px] lg:w-[56rem]"
              />
            </div>
          </div>
        </CenterWrapper>

        {/* clients */}
        <Partner />
        <Footer />
      </div>
    </>
  );
};

export default Home;
