import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const ManufacturingExecution = () => {
  const introData = {
    icon: "/assets/svg/home//manufacturing-execution.gif",
    title: "Manufacturing Collaboration",
    desc: "Boost productivity, enhance quality control, improve inventory management, enable seamless execution, and ensure scalability and flexibility for future growth.",
    illustration: "/assets/svg/product/manufacturing-execution-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/manufacturing-execution.png",
    desc: "Create a bridges and highways that connect different departments and systems seamlessly, ensuring smooth traffic flow and efficient communication between them.",
    provides: [
      {
        icon: "icon-list",
        title: "Boost Productivity",
        desc: "Our app optimizes manufacturing processes, reducing downtime and maximizing output. With streamlined workflows and real-time monitoring, your team can work more efficiently, ensuring that every minute counts towards meeting production targets.",
      },
      {
        icon: "icon-workflow",
        title: "Enable Seamless Execution",
        desc: "Facilitates seamless coordination across departments, from production scheduling to logistics management. With centralized communication and task assignment features, everyone stays on the same page, minimizing misunderstandings and delays.",
      },
      {
        icon: "icon-scaleup",
        title: "Scalability and Flexibility",
        desc: "Our app scales effortlessly with your business, accommodating increased production volumes and evolving requirements. Whether you're expanding into new markets or introducing innovative products, our flexible solution adapts to support your growth every step of the way",
      },
    ],
  };

  return (
    <>
      <Header />
      <ProductTemplate introData={introData} productDetails={productDetails} />
      <Footer />
    </>
  );
};

export default ManufacturingExecution;
