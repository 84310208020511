import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProductTemplate from "../../components/ProductTemplate";

const AssetManagement = () => {
  const introData = {
    icon: "/assets/svg/home/fulfillment.gif",
    title: "Asset Management",
    desc: "Ultimate solution for organizing, tracking, and maximizing the value of your company's assets",
    illustration: "/assets/svg/product/asset-management-illus.svg",
  };

  const productDetails = {
    image: "/assets/svg/product/asset-management.png",
    desc: "Asset Management empowers you to extract maximum value from your assets, minimize risks, and drive operational excellence. It's not just about keeping track of your stuff, it's about unlocking the full potential of your resources to fuel growth and success",
    provides: [
      {
        icon: "icon-timer",
        title: "Customizable Dashboards",
        desc: "Tailor the app to your specific needs with customizable dashboards and reports. Whether you're a small business or a multinational corporation, you can configure the app to display the metrics and insights most relevant to your operations",
      },
      {
        icon: "icon-efficient",
        title: "Streamlined Procurement",
        desc: "Simplify the procurement process by integrating with purchasing systems. From requesting quotes to tracking orders, the app streamlines the entire procurement lifecycle, saving time and reducing administrative overhead.",
      },
      {
        icon: "icon-data",
        title: "Centralized Asset Repository",
        desc: "Say goodbye to scattered spreadsheets and endless searches. This app consolidates all your asset information in one place, making it easy to locate, update, and manage assets efficiently.",
      },
    ],
  };

  return (
    <div className="overflow-hidden">
      <Header />
      <ProductTemplate introData={introData} productDetails={productDetails} />
      <Footer />
    </div>
  );
};

export default AssetManagement;
